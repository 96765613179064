import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import Select from 'react-select'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap'
import { useFormik } from 'formik'
import { Multiselect } from 'multiselect-react-dropdown'
import * as Yup from 'yup'
import nowLogo from '../../assets/img/tabo-logo.png'
import style from '../../assets/scss/additional/select.module.scss'
import bgImage from '../../assets/img/loginpage.jpg'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCountryAction,
  registerAction,
  registerValue,
} from '../../redux/business/action'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import '../../assets/css/custom.scss'
import axios from 'axios'
import { REST_API_URL } from '../../utils/constants'
import Iframe from 'react-iframe'
import moment from 'moment'
import 'react-notifications-component/dist/theme.css'

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })
  const [display, setDisplay] = useState(false)
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
      if (window.innerWidth < 960) {
        setDisplay(true)
      }
      if (window.innerWidth > 960) {
        setDisplay(false)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return display
}

const RegisterPage = () => {
  const size = useWindowSize()
  const [state, setState] = useState({ country: null })
  const [allowRegister, setAllowRegister] = useState(true)
  const [countryList, setCounryList] = useState([])
  const business = useSelector(({ business }) => business)
  const [visible, setVisible] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const [terms, setTerms] = useState([])
  const [currentTerms, setCurrentTerms] = useState({})
  const filterOptions = (candidate, input) => {
    if (input) {
      return candidate.label.toLowerCase().startsWith(input.toLowerCase())
    }
    return true
  }
  const changeLogin = () => {
    history.push('/login')
  }
  useEffect(() => {
    dispatch(getCountryAction())
    getTermsCondition()
  }, [])
  useEffect(() => {
    if (business.countryData) {
      let arr = []
      business.countryData.map((item, index) => {
        arr.push({ value: item.id, label: item.name })
      })
      setCounryList(arr)
    }
  }, [business.countryData])
  const onDismiss = () => {
    setVisible(!visible)
  }
  const dispatch = useDispatch()
  const history = useHistory()
  const allowPolicy = (event) => {
    setAllowRegister(!event.target.checked)
    /* if (event.target.checked) {
      openTermsConditions()
    } */
  }
  const { t, i18n } = useTranslation()
  const selectResources = [
    { value: 'Pool', label: t('pool') },
    { value: 'Beach', label: t('beach') },
    { value: 'Restaurant', label: t('restaurant') },
    { value: 'Club', label: t('club') },
    { value: 'Bar', label: t('bar') },
  ]
  var selectPositions = [
    { value: 'Owner', label: t('Owner') },
    { value: 'Administrator', label: t('Administrator') },
    { value: 'Company Representative', label: t('Company Representative') },
    { value: 'Manager', label: t('Manager') },
  ]

  const getTermsCondition = async () => {
    const termsConditions = await axios.get(
      `${REST_API_URL}/get-business-terms-and-conditions`,
    )
    setTerms(termsConditions.data)
  }

  const getCurrentTermsConditions = () => {
    const lang = i18n.language == 'en-US' ? 'en' : i18n.language
    const ct = terms.filter((terms) => {
      return terms.language === lang
    })
    setCurrentTerms(ct[0])
    return ct
  }

  const openTermsConditions = () => {
    getCurrentTermsConditions()
    setShowTerms(true)
  }

  const openInNewPage = (URL) => {
    window.open(URL, '_blank')
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      position: '',
      phoneNumber: '',
      country: '',
      email: '',
      password: '',
      interested_in: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      phoneNumber: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
    }),
    onSubmit: (values) => {
      let arr = []
      arr.push(values)
      arr.map((item, index) => {
        if (state.position) {
          arr[index].position = state.position.value
        }
        if (state.country) {
          arr[index].country = state.country.value
        }
        if (state.interested_in && state.interested_in.length > 0) {
          arr[index].interested_in = state.interested_in
        }
      })
      arr[0].terms_condition_version = terms[0].version
      arr[0].terms_condition_accepted_date = moment().format(
        'YYYY-MM-DD HH:mm:ss',
      )
      dispatch(registerValue(arr[0]))
      dispatch(registerAction(history))
    },
  })
  return (
    <>
      <div style={{ position: 'absolute', top: '50px', right: '50px' }}>
        <Alert
          color="info"
          className="alert-with-icon"
          style={{ zIndex: '200' }}
          isOpen={business.loginStatus}
          toggle={onDismiss}
        >
          <span data-notify="icon" className="now-ui-icons ui-1_bell-53"></span>
          <span data-notify="message">{business.registerMessage}</span>
        </Alert>
      </div>
      <div className="content" style={{ paddingTop: '80px' }}>
        <div className="login-page">
          <Container className="container">
            <Row className="justify-content-center align-items-center">
              <CardHeader>
                <div className="logo-container">
                  <img src={nowLogo} alt="smart-beach-logo" />
                </div>
              </CardHeader>
            </Row>
            <Row className="justify-content-center align-items-center">
              <Col lg={7} md={8} xs={12} className={size ? 'd-none' : ''}>
                <div className="info-area info-horizontal">
                  <div className="icon icon-primary">
                    <i className="now-ui-icons media-2_sound-wave" />
                  </div>
                  <div className="description">
                    <h5 className="info-title">{t('register-text-1')}</h5>
                    <p className="description">{t('register-text-body-1')}</p>
                  </div>
                </div>
                <div className="info-area info-horizontal">
                  <div className="icon icon-primary">
                    <i className="now-ui-icons media-1_button-pause" />
                  </div>
                  <div className="description">
                    <h5 className="info-title">{t('register-text-2')}</h5>
                    <p className="description">{t('register-text-body-2')}</p>
                  </div>
                </div>
                <div className="info-area info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons users_single-02" />
                  </div>
                  <div className="description">
                    <h5 className="info-title">{t('register-text-3')}</h5>
                    <p className="description">{t('register-text-body-3')}</p>
                  </div>
                </div>
                <div className="info-area info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons users_single-02" />
                  </div>
                  <div className="description">
                    <h5 className="info-title">{t('register-text-4')}</h5>
                    <p className="description">{t('register-text-body-4')}</p>
                  </div>
                </div>
              </Col>
              <Col lg={5} md={8} xs={12}>
                <Form onSubmit={formik.handleSubmit}>
                  <Card className="card-login card-plain">
                    <CardHeader className="text-center">
                      <CardTitle tag="h4">{t('register')}</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <InputGroup
                        /* className={
                          'no-border form-control-lg px-4px' +
                          (state.firstnameFocus ? 'input-group-focus' : '')
                        } */
                        className={
                          'no-border form-control-lg px-4px ' +
                          (state.emailFocus ? 'input-group-focus' : '')
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder={t('First Name')}
                          name="firstName"
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                        />
                      </InputGroup>
                      {formik.errors.firstName && formik.touched.firstName && (
                        <FormText color="danger">
                          {formik.errors.firstName}
                        </FormText>
                      )}
                      <InputGroup
                        className={
                          'no-border form-control-lg  px-4px' +
                          (state.firstnameFocus ? 'input-group-focus' : '')
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder={t('Last Name')}
                          name="lastName"
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                        />
                      </InputGroup>
                      {formik.errors.lastName && formik.touched.lastName && (
                        <FormText color="danger">
                          {formik.errors.lastName}
                        </FormText>
                      )}
                      <InputGroup
                        className={
                          'no-border px-4px register-dropdown' +
                          (state.countryFocus ? 'input-group-focus' : '')
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons location_pin" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Select
                          className={classNames(
                            'react-select basic-font position-select-style',
                          )}
                          classNamePrefix="react-select"
                          placeholder={t('Position')}
                          name="position"
                          options={selectPositions}
                          value={state.position}
                          onChange={(value) =>
                            setState({ ...state, position: value })
                          }
                        />
                      </InputGroup>
                      <InputGroup
                        className={
                          'no-border form-control-lg  px-4px' +
                          (state.emailFocus ? 'input-group-focus' : '')
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="email"
                          placeholder={t('Email')}
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                        />
                      </InputGroup>
                      {formik.errors.email && formik.touched.email && (
                        <FormText color="danger">
                          {formik.errors.email}
                        </FormText>
                      )}
                      <InputGroup
                        className={
                          'no-border form-control-lg  px-4px' +
                          (state.phoneNumberFocus ? 'input-group-focus' : '')
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons tech_mobile" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="number"
                          placeholder={t('Phone Number')}
                          name="phoneNumber"
                          value={formik.values.phoneNumber}
                          onChange={formik.handleChange}
                        />
                      </InputGroup>
                      {formik.errors.phoneNumber &&
                        formik.touched.phoneNumber && (
                          <FormText color="danger">
                            {formik.errors.phoneNumber}
                          </FormText>
                        )}
                      <InputGroup
                        className={
                          'no-border form-control-lg  px-4px' +
                          (state.passwordFocus ? 'input-group-focus' : '')
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder={t('password')}
                          name="password"
                          autoComplete="new-password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                        />
                      </InputGroup>
                      {formik.errors.password && formik.touched.password && (
                        <FormText color="danger">
                          {formik.errors.password}
                        </FormText>
                      )}
                      {countryList.length > 0 && (
                        <InputGroup
                          className={
                            'no-border  px-4px register-dropdown ' +
                            (state.countryFocus ? 'input-group-focus' : '')
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons business_globe" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Select
                            className={classNames(
                              'react-select basic-font country-select-style',
                            )}
                            classNamePrefix="react-select"
                            placeholder={t('Country')}
                            name="country"
                            options={countryList}
                            value={state.country}
                            filterOption={filterOptions}
                            onChange={(value) =>
                              setState({ ...state, country: value })
                            }
                          />
                        </InputGroup>
                      )}
                      <InputGroup
                        className={
                          'no-border px-4px' +
                          (state.countryFocus ? 'input-group-focus' : '')
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons files_box" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Multiselect
                          name="interested_in"
                          options={selectResources}
                          selectedValues={state.interested_in}
                          onSelect={(v) => {
                            setState({ ...state, interested_in: v })
                          }}
                          onRemove={(v) => {
                            setState({ ...state, interested_in: v })
                          }}
                          style={{ color: '#ffffff' }}
                          showCheckbox={true}
                          displayValue="label"
                          placeholder={t('Interested in')}
                        />
                      </InputGroup>
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" onClick={allowPolicy} />
                          <span className="form-check-sign" />
                          <div>
                            {t('I agree to the')}{' '}
                            <a
                              className="termsconditions"
                              target="_blank"
                              href="/termsandconditions.html"
                            >
                              {t('terms and conditions')}
                            </a>
                            .
                          </div>
                        </Label>
                      </FormGroup>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        color="primary"
                        size="lg"
                        className="btn-round"
                        type="submit"
                        disabled={allowRegister}
                      >
                        {t('Send Request')}
                      </Button>
                    </CardFooter>
                    <CardFooter
                      style={{ marginTop: '0px' }}
                      className="text-center"
                    >
                      <h6>
                        <a
                          style={{ cursor: 'pointer' }}
                          onClick={changeLogin}
                          className="link footer-link"
                        >
                          {t('Login')}
                        </a>
                      </h6>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Modal
        isOpen={showTerms}
        toggle={() => {
          setShowTerms(false)
        }}
        autoFocus={false}
        size="xl"
      >
        <ModalBody>
          {currentTerms && (
            <Iframe
              url={currentTerms?.url}
              width="100%"
              height="500px"
              id="myId"
              className="myClassname"
              display="initial"
              loading="Please wait ... loading content"
              position="relative"
            />
          )}
        </ModalBody>
        <ModalFooter>
          <div style={{ width: '100%', textAlign: 'end' }}>
            <Button
              className="save-btn-secondary"
              onClick={() => setShowTerms(false)}
            >
              {t('estimate_alert_cancel_text')}
            </Button>
            <Button
              className="save-btn-secondary"
              onClick={() => {
                openInNewPage(currentTerms?.url)
              }}
              style={{ marginLeft: '15px' }}
            >
              {t('term_condition_link')}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <div
        className="full-page-background"
        style={{ backgroundImage: 'url(' + bgImage + ')' }}
      ></div>
    </>
  )
}
export default RegisterPage
