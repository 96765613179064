import EmployeesPage from "./views/Pages/adminPage/Employees.js";
import GridPlannerPage from "./views/Pages/adminPage/GridPlanner";
import SettingsPage from "./views/Pages/adminPage/Settings";
import OptionsPage from "./views/Pages/adminPage/Options.js";
import OptionsNewPage from "./views/Pages/adminPage/OptionsNew.js";
//import GridPage from './views/Pages/adminPage/Grid.js';
import GridPage from "./views/Pages/adminPage/GridPage";
import PhotoElementsPage from "./views/Pages/adminPage/PhotoElements";
import CalendarPage from "./views/Pages/adminPage/Calendar.js";
import Sms from "./views/Pages/adminPage/Sms.js";
import PricePlannerPage from "./views/Pages/adminPage/PricePlanner.js";
import LoginPage from "./views/Pages/LoginPage.js";
import RegisterPage from "./views/Pages/RegisterPage.js";
import WizardPage from "./views/Pages/Wizard.js";
import BusinessPage from "./views/Pages/BusinessProfile.js";
import BusinessPageOld from "./views/Pages/BusinessProfileOld.js";
import PlanPage from "./views/Pages/PlanPage.js";

import AdminWizard from "./views/Pages/AdminWizard";
import AdminWizardNew from "./views/Pages/AdminWizardNew";
import ResetPage from "./views/Pages/PasswordResetPage-1";
import ConfirmPage from "./views/Pages/ConfirmPassword";
import LocationsPage from "./views/Pages/SuperAdminPage/Locations.js";
import InvociesPage from "./views/Pages/SuperAdminPage/Invoices";
import FeeInvociesPage from "./views/Pages/SuperAdminPage/FeeInvoices";

import RequestRegisterPage from "./views/Pages/SuperAdminPage/RequestRegister.js";
import UploadMenuPage from "./views/Pages/adminPage/UploadMenu.js";
import GuestPage from "./views/Pages/adminPage/Guest.js";
import SmsHistory from "./views/Pages/adminPage/SmsHistory.js";
import SmsLogs from "./views/Pages/adminPage/SmsLogs.js";
import SmsInvoices from "./views/Pages/SuperAdminPage/SmsInvoices.js";
import FeeInvoicePage from "./views/Pages/FeeInvoicePage.js";
import SmsInvoicePage from "./views/Pages/SmsInvoicePage.js";

let routes = [
  {
    path: "login",
    name: "Login Page",
    component: LoginPage,
    layout: "/",
    show: false,
  },
  {
    path: "register",
    name: "Register Page",
    component: RegisterPage,
    layout: "/",
    show: false,
  },
  {
    path: "reset",
    name: "Reset Page",
    component: ResetPage,
    layout: "/",
    show: false,
  },
  {
    path: "confirm/:id",
    name: "Confirm Page",
    component: ConfirmPage,
    layout: "/",
    show: false,
  },
  {
    path: "wizard",
    name: "Wizard Page",
    component: WizardPage,
    layout: "/",
    show: false,
  },
  {
    path: "/wizardold",
    name: "Wizard Page",
    component: AdminWizard,
    layout: "/admin",
    show: false,
  },
  {
    path: "/wizard",
    name: "Wizard Page",
    component: AdminWizardNew,
    layout: "/admin",
    show: false,
  },
  {
    path: "/business/:id",
    name: "Business Page",
    component: BusinessPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/plan",
    name: "Plan",
    component: PlanPage,
    layout: "/admin",
    icon: "now-ui-icons business_money-coins",
    show: true,
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: CalendarPage,
    layout: "/admin",
    icon: "now-ui-icons design_app",
    show: false,
  },
  {
    path: "/dashboard",
    name: "Plan",
    component: GridPlannerPage,
    layout: "/admin",
    icon: "now-ui-icons design_app",
    show: false,
  },
  {
    path: "/sms",
    name: "Sms",
    component: Sms,
    layout: "/admin",
    icon: "now-ui-icons ui-1_email-85",
    show: true,
    exact: true,
    subMenu: [
      {
        path: "/sms",
        name: "Sms",
        component: Sms,
        layout: "/admin",
        icon: "now-ui-icons ui-1_settings-gear-63",
        show: false,
        exact: true,
      },
      {
        path: "/sms/history",
        name: "History",
        component: SmsHistory,
        layout: "/admin",
        icon: "now-ui-icons education_paper",
        show: true,
        exact: true
      },
      {
        path: "/sms/sms-logs",
        name: "SMS logs",
        component: SmsLogs,
        layout: "/admin",
        icon: "now-ui-icons files_box",
        show: true,
        exact: true
      },
      {
        path: "/smsInvocies",
        name: "Sms Invocies",
        component: SmsInvoices,
        layout: "/admin",
        icon: "now-ui-icons business_money-coins",
        show: true,
      }
    ],
  },
  {
    path: "/upload-menu",
    name: "Menu",
    layout: "/admin",
    component: UploadMenuPage,
    icon: "now-ui-icons files_paper",
    show: true,
  },
  {
    path: "/settings",
    name: "Settings",
    component: SettingsPage,
    layout: "/admin",
    icon: "now-ui-icons ui-1_settings-gear-63",
    show: true,
  },
  //   {
  //     path: "/gridPlan",
  //     name: "Grid planner",
  //     component: GridPlannerPage,
  //     layout: "/admin",
  //     icon: "now-ui-icons location_map-big",
  //     show: true,
  //   },
  {
    path: "/beachmap",
    name: "Grid",
    component: GridPage,
    layout: "/admin",
    icon: "now-ui-icons design_vector",
    show: true,
  },
  //   {
  //     path: "/option",
  //     name: "Options",
  //     component: OptionsNewPage,
  //     layout: "/admin",
  //     icon: "now-ui-icons  business_briefcase-24",
  //     show: true,
  //   },
  {
    path: "/price",
    name: "Price planner",
    component: PricePlannerPage,
    layout: "/admin",
    icon: "now-ui-icons  business_money-coins",
    show: true,
  },
  // {
  // 	path: '/elements',
  // 	name: 'Photo elements',
  // 	component: PhotoElementsPage,
  // 	layout: '/admin',
  // 	icon: 'now-ui-icons design_image',
  // 	show: true,
  // },
  {
    path: "/employee",
    name: "Employees",
    component: EmployeesPage,
    layout: "/admin",
    icon: "now-ui-icons business_badge",
    show: true,
  },
  {
    path: "/guest",
    name: "Guests",
    component: GuestPage,
    layout: "/admin",
    icon: "now-ui-icons business_badge",
    show: true,
  },
  {
    path: "/register_requests",
    name: "Register Requests",
    component: RequestRegisterPage,
    layout: "/super_admin",
    icon: "now-ui-icons business_badge",
    show: true,
  },
  {
    path: "/locations",
    name: "Locations",
    component: LocationsPage,
    layout: "/super_admin",
    icon: "now-ui-icons business_money-coins",
    show: true,
  },
  {
    path: "/invocies",
    name: "Invocies",
    component: InvociesPage,
    layout: "/super_admin",
    icon: "now-ui-icons business_money-coins",
    show: true,
  },
  {
    path: "/feeInvocies",
    name: "Fee Invocies",
    component: FeeInvociesPage,
    layout: "/super_admin",
    icon: "now-ui-icons business_money-coins",
    show: true,
  },
  {
    path: "/smsInvocies",
    name: "Sms Invocies",
    component: SmsInvoices,
    layout: "/super_admin",
    icon: "now-ui-icons business_money-coins",
    show: true,
  },
  {
    path: "/feeInvocies",
    name: "Fee Invocies",
    component: FeeInvociesPage,
    layout: "/admin",
    icon: "now-ui-icons business_money-coins",
    show: true,
  },
  {
    path: "feeInvoice/:invoiceNumber",
    name: "Fee Invoice",
    component: FeeInvoicePage,
    layout: "/",
    show: false,
  },
  {
    path: "smsInvoice/:invoiceNumber",
    name: "Fee Invoice",
    component: SmsInvoicePage,
    layout: "/",
    show: false,
  },
  // {
  //   path: "/menu",
  //   name: "Menu",
  //   component: MenuPage,
  //   layout: "/admin",
  //   icon: "now-ui-icons files_paper",
  //   show: true,
  // },
  // {
  //   path: "/support",
  //   name: "Support",
  //   component: SupportPage,
  //   layout: "/admin",
  //   icon: "now-ui-icons location_world",
  //   show: true,
  // },
  // {
  //   path: "/static",
  //   name: "Statics",
  //   component: StaticsPage,
  //   layout: "/admin",
  //   icon: "now-ui-icons business_chart-bar-32",
  //   show: true,
  // },
  // {
  //   path: "/customer",
  //   name: "My customers",
  //   component: MyCustomersPage,
  //   layout: "/admin",
  //   icon: "now-ui-icons ui-2_favourite-28",
  //   show: true,
  // },
  // {
  //   path: "/review",
  //   name: "Reviews",
  //   component: ReviewsPage,
  //   layout: "/admin",
  //   icon: "now-ui-icons ui-2_chat-round",
  //   show: true,
  // },
  // {
  //   path: "/events",
  //   name: "Events",
  //   component: EventsPage,
  //   layout: "/admin",
  //   icon: "now-ui-icons location_pin",
  //   show: true,
  // },
];

export default routes;
