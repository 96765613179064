import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import './output.css';
import './assets/scss/smart-beach.scss';
import './assets/css/demo.css';
import AdminLayout from './layouts/Admin.js';
import SuperAdminLayout from './layouts/SuperAdmin.js';
import UserLayout from './layouts/User.js';
import PageLayout from './layouts/Page.js';
import { checkIfAllPricesAreSet } from './redux/price/action.js';
import { getStripeTOSStatus } from './redux/business/action.js';
import ReactGA from 'react-ga';
const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

const hist = createBrowserHistory();

class App extends PureComponent {
	componentDidMount() {
		const { business, dispatch, } = this.props;

		if (business?.filterBusinessList?.id) {
			checkIfAllPricesAreSet(business?.filterBusinessList?.id, dispatch);
			getStripeTOSStatus(business?.filterBusinessList?.id, dispatch);
		}
		ReactGA.pageview(window.location.pathname);
	}

	render() {
		const { business } = this.props;
		return (
			<Router history={ hist }>
				<Switch>
					<Route
						path="/admin"
						render={ (props) => {
							return business.accessToken ? (
								<AdminLayout { ...props } />
							) : (
								<Redirect to="/login"/>
							);
						} }
					/>
					<Route
						path="/super_admin"
						render={ (props) => {
							return business.accessToken && business.superAdmin ? (
								<SuperAdminLayout { ...props }/>
							) : (
								<Redirect to="/admin"/>
							);
						} }
					/>
					<Route
						path="/user"
						render={ (props) => {
							return <UserLayout { ...props } />;
						} }
					/>
					<Route
						path="/"
						render={ (props) => {
							return <PageLayout { ...props } />;
						} }
					/>
				</Switch>
			</Router>
		);
	}
}

const mapStateToProps = store => ( {
	business: store.business,
} );

export default connect(mapStateToProps)(App);
