import { REST_API_URL } from './constants.js';

const getToken = () => {
	let token;
	let localState = localStorage.getItem('persist:business');
	if (localState && typeof JSON.parse(localState) === 'object') {
		localState = JSON.parse(localState);
		token = JSON.parse(localState.accessToken);
	}

	return token;
};

const generateAuthenticationHeaders = (multipart, token = getToken()) => {
	return {
		'Content-type': multipart ? 'multipart/form-data' : 'application/json',
		Accept: 'application/json',
		Authorization: `Bearer ${token}`,
		'x-token': token,
	};
};

export const calls = {
	getAllBusinesses: token => ({
		url: `${REST_API_URL}/business`,
		method: 'GET',
		headers: generateAuthenticationHeaders(null, token),
	}),
	getPendingClients: () => ({
		url: `${REST_API_URL}/client`,
		method: 'GET',
		headers: generateAuthenticationHeaders(),
	}),
	updateClientStatus: (client, status) => ({
		url: `${REST_API_URL}/client`,
		method: 'PUT',
		headers: generateAuthenticationHeaders(),
		data: {
			client,
			status
		}
	}),
	updateBusiness: (business, updateData) => ({
		url: `${REST_API_URL}/business`,
		method: 'PUT',
		headers: generateAuthenticationHeaders(),
		data: {
			business,
			updateData
		}
	}),
	getShadowedUserToken: (clientId) => ({
		url: `${REST_API_URL}/client/shadow`,
		method: 'POST',
		headers: generateAuthenticationHeaders(),
		data: {
			clientId
		}
	}),
	checkIfAllPricesAreSet: businessId => ({
		url: `${REST_API_URL}/business/${businessId}/settings/timeframe-prices/status`,
		method: 'GET',
		headers: generateAuthenticationHeaders(),
	}),
	deletePrice: (data) => ({
		url: `${REST_API_URL}/price/deletePriceByTypes`,
		method: 'POST',
		headers: generateAuthenticationHeaders(),
		data: data
	}),
	uploadMenu: (data, businessId) => ({
		url: `${REST_API_URL}/business/${businessId}/uploads/menu`,
		method: 'POST',
		headers: generateAuthenticationHeaders(true),
		data: data
	}),
	updateMenuQrcode: (data, businessId) => ({
		url: `${REST_API_URL}/business/${businessId}/update_menu_qrcode`,
		method: 'POST',
		headers: generateAuthenticationHeaders(true),
		data: data
	}),
	deleteMenu: (businessId) => ({
		url: `${REST_API_URL}/business/${businessId}/uploads/menu`,
		method: 'DELETE',
		headers: generateAuthenticationHeaders()
	}),
	getMenu: (businessId) => ({
		url: `${REST_API_URL}/business/${businessId}/uploads/menu`,
		method: 'GET',
		headers: generateAuthenticationHeaders()
	}),
	updateActivityDatePeriod: (businessId, data) => ({
		url: `${REST_API_URL}/business/${businessId}/settings/activity-period`,
		method: 'PUT',
		headers: generateAuthenticationHeaders(),
		data: data,
	}),
	createStripeAccount: businessId => ({
		url: `${REST_API_URL}/stripe/${businessId}`,
		method: 'PATCH',
		headers: generateAuthenticationHeaders(),
	}),
	getStripeTOSStatus: businessId => ({
		url: `${REST_API_URL}/stripe/${businessId}`,
		method: 'GET',
		headers: generateAuthenticationHeaders(),
	}),
	agreeStripeTOS: businessId => ({
		url: `${REST_API_URL}/stripe/${businessId}/terms-of-service`,
		method: 'PATCH',
		headers: generateAuthenticationHeaders(),
	}),
	sendEmail: (data) => ({
		url: `${REST_API_URL}/emails`,
		method: 'POST',
		headers: generateAuthenticationHeaders(),
		data: data,
	}),
	sendUserAcceptedEmail: data => ({
		url: `${REST_API_URL}/user/${data}/email/accept`,
		method: 'GET',
		headers: generateAuthenticationHeaders(),
	}),
	sendUserRejectedEmail: data => ({
		url: `${REST_API_URL}/user/${data}/email/reject`,
		method: 'GET',
		headers: generateAuthenticationHeaders(),
	}),
	deleteZone: data => ({
		url: `${REST_API_URL}/zone/${data}`,
		method: 'DELETE',
		headers: generateAuthenticationHeaders(),
	}),
	updateUserPermission: (id, permissions, business_id) => ({
		url: `${REST_API_URL}/user/updateUserPermission`,
		method: 'PUT',
		headers: generateAuthenticationHeaders(),
		data: {
			id,
			permissions,
			business_id
		}
	}),
	stripAccountStatusApi : (business_id) => ({
		url: `${REST_API_URL}/stripe/${business_id}/account-status`,
		method: 'GET',
		headers: generateAuthenticationHeaders()
	}),
	getAllInvoices: (token, startDate, endDate) => ({
		url: `${REST_API_URL}/invoices?startDate=${startDate}&endDate=${endDate}`,
		method: 'GET',
		headers: generateAuthenticationHeaders(null, token),
	}),
	
	getAllFeeInvoices: (token, startDate, endDate, business_id) => ({
		url: `${REST_API_URL}/feeInvoices?startDate=${startDate}&endDate=${endDate}&business_id=${business_id}`,
		method: 'GET',
		headers: generateAuthenticationHeaders(null, token),
	}),
	
	getFeeInvoice: (invoiceNumber) => ({
		url: `${REST_API_URL}/feeInvoices/${invoiceNumber}`,
		method: 'GET',
		headers: generateAuthenticationHeaders(null, ""),
	}),
	
	getSmsInvoice: (invoiceNumber) => ({
		url: `${REST_API_URL}/smsInvoices/${invoiceNumber}`,
		method: 'GET',
		headers: generateAuthenticationHeaders(null, ""),
	}),
	
	getAllSmsInvoices: (token, startDate, endDate, business_id) => ({
		url: `${REST_API_URL}/smsInvoices?startDate=${startDate}&endDate=${endDate}&business_id=${business_id}`,
		method: 'GET',
		headers: generateAuthenticationHeaders(null, token),
	}),
	
	getAllGuestList: (token,businessId) => ({
		url: `${REST_API_URL}/customer_alias/getAllAlias/${businessId}`,
		method: 'GET',
		headers: generateAuthenticationHeaders(null, token),
	}),
	
	createGuest: (token,data) => ({
		url: `${REST_API_URL}/customer_alias/create`,
		method: 'POST',
		headers: generateAuthenticationHeaders(null, token),
		data : data
	}),
	
	updateGuest: (token,id,data) => ({
		url: `${REST_API_URL}/customer_alias/update/${id}`,
		method: 'PUT',
		headers: generateAuthenticationHeaders(null, token),
		data : data
	}),
	
	deleteGuest: (token,id) => ({
		url: `${REST_API_URL}/customer_alias/delete/${id}`,
		method: 'DELETE',
		headers: generateAuthenticationHeaders(null, token),
	}),
	
	searchCustomerByNumber: (token,prefix, phone) => ({
		url: `${REST_API_URL}/customer_alias/searchCustomerByNumber`,
		method: 'POST',
		headers: generateAuthenticationHeaders(null, token),
		data: {
			prefix: prefix,
        	phone: phone
		}
	}),
	uploadLogo: (token,data) => ({
		url: `${REST_API_URL}/upload/business-logo`,
		method: 'POST',
		headers: generateAuthenticationHeaders(true, token),
		data : data
	}),
	removeLogo: (token,businessId) => ({
		url: `${REST_API_URL}/upload/remove-business-logo/${businessId}`,
		method: 'DELETE',
		headers: generateAuthenticationHeaders(true, token)
	}),
	createStripeCheckoutSession: (data) => ({
		url:`${REST_API_URL}/stripe/create-checkout-session`,
		method:"POST",
		data
	}),
	getTopupHistory:(clientId,data)=>({
		url:`${REST_API_URL}/business/${data.business_id}/getSMSTopUpHistory`,
		method: 'GET',
		data
	}),
	getSmsBalance:(data,clientId)=>({
		url:`${REST_API_URL}/client/${clientId}/getSmsBalance`,
		method: 'POST',
		data
	}),
	
	purchaseSMSPlan : (data,clientId) => ({
		url:`${REST_API_URL}/client/${clientId}/purchaseSMSPlan`,
		method: 'POST',
		data
	}),
	
	getSavedCardForBusiness : (data,clientId) => ({
		url:`${REST_API_URL}/client/${clientId}/getSavedCardForBusiness`,
		method: 'POST',
		data
	}),
	
	getExchangeRate : (date) => ({
		url:`${REST_API_URL}/exchangeRates?date=${date}`,
		method: 'GET',
	})
};
