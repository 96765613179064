import React, { PureComponent } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class CustomModal extends PureComponent {
	render() {
		const { open, body, actions, onAgree, onCancel, handleModal, t, } = this.props;

		return (
			<Modal
				isOpen={ open }
				toggle={ handleModal ? handleModal(false) : null }>
				<ModalBody>
					{ body }
				</ModalBody>
				<ModalFooter>
					{ actions ? actions : (
						<>
							<Button
								color="success"
								onClick={ onAgree }>
								{ t('Save') }
							</Button>
							<Button
								color="danger"
								onClick={ onCancel || ( handleModal ? handleModal(false) : null ) }>
								{ t('Cancel') }
							</Button>
						</>
					) }
				</ModalFooter>
			</Modal>
		);
	}
}

export default withTranslation()(CustomModal);